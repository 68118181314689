import { graphql } from 'gatsby'
import React from 'react'

import TrendingController from '../screens/trending'

const Events = (props) => <TrendingController {...props} />

export default Events

export const pageQuery = graphql`
  query ($lang: String) {
    allWpEvent(
      sort: { fields: dateGmt, order: DESC }
      filter: { locale: { locale: { eq: $lang } } }
    ) {
      edges {
        node {
          id
          title
          slug
          nodeType
          excerpt
          uri
          date(formatString: "MMM DD, YYYY")
          dateGmt
          categories {
            nodes {
              name
              slug
              description
              id
            }
          }
          acf: acfEvents {
            eventDatetime {
              endDate
              endTime
              fieldGroupName
              startDate
              startTime
            }
            eventLocation
            readingDuration
          }
          featuredImage {
            node {
              id
              altText
              sourceUrl
            }
          }
        }
      }
    }
    allWpUniversityPost(
      sort: { fields: dateGmt, order: DESC }
      filter: { locale: { locale: { eq: $lang } } }
    ) {
      edges {
        node {
          id
          title
          slug
          nodeType
          excerpt
          uri
          date(formatString: "MMM DD, YYYY")
          dateGmt
          acf: acfUniversityPost {
            readingDuration
          }
          categories {
            nodes {
              name
              slug
              description
              id
            }
          }
          featuredImage {
            node {
              id
              altText
              sourceUrl
            }
          }
        }
      }
    }
    allWpProductSingleNews(
      sort: { fields: dateGmt, order: DESC }
      filter: { locale: { locale: { eq: $lang } } }
    ) {
      edges {
        node {
          id
          title
          slug
          nodeType
          excerpt
          uri
          date(formatString: "MMM DD, YYYY")
          dateGmt
          acf: acfProductNews {
            readingDuration
          }
          categories {
            nodes {
              name
              slug
              description
              id
            }
          }
          featuredImage {
            node {
              id
              altText
              sourceUrl
            }
          }
        }
      }
    }
    allWpReportsAndanalysis(
      sort: { fields: dateGmt, order: DESC }
      filter: { locale: { locale: { eq: $lang } } }
    ) {
      edges {
        node {
          id
          title
          slug
          nodeType
          excerpt
          uri
          date(formatString: "MMM DD, YYYY")
          dateGmt
          acf: acfReportAndAnalysis {
            readingDuration
          }
          categories {
            nodes {
              name
              slug
              description
              id
            }
          }
          featuredImage {
            node {
              id
              altText
              sourceUrl
            }
          }
        }
      }
    }
    allWpCompanySingleNews(
      sort: { fields: dateGmt, order: DESC }
      filter: { locale: { locale: { eq: $lang } } }
    ) {
      edges {
        node {
          id
          title
          slug
          nodeType
          excerpt
          uri
          date(formatString: "MMM DD, YYYY")
          dateGmt
          acf: acfCompanyNews {
            readingDuration
          }
          categories {
            nodes {
              name
              slug
              description
              id
            }
          }
          featuredImage {
            node {
              id
              altText
              sourceUrl
            }
          }
        }
      }
    }
    allWpMarketUpdate(sort: { fields: dateGmt, order: DESC }) {
      edges {
        node {
          id
          title
          slug
          nodeType
          excerpt
          uri
          date(formatString: "MMM DD, YYYY")
          dateGmt
          acf: acfMarketUpdates {
            readingDuration
          }
          categories {
            nodes {
              name
              slug
              description
              id
            }
          }
          featuredImage {
            node {
              id
              altText
              sourceUrl
            }
          }
        }
      }
    }
  }
`
