import _ from 'lodash'

export const getTrendingPost = (el, number = 4) => {
  const trendingPost = _.filter(el, (event) => {
    const element = !_.isEmpty(event.node) ? event.node : event
    if (!_.isEmpty(element.categories)) {
      return !!~_.map(element.categories.nodes, (item) =>
        _.includes(item.slug, 'trending')
      ).indexOf(true)
    }
  })
  return trendingPost.slice(0, number)
}
