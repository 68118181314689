import './trending-style.scss'

import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  AdsSection,
  CardGroupHeader,
  CardList,
  CardSection,
  Seo,
} from '../../components'
import { ViewMobile } from './components'

const Trending = ({
  data,
  mobileViewData,
  trendingData,
  eventsData,
  universityData,
  companyData,
  productsData,
  // nftStoriesData,
}) => {
  const { t, i18n } = useTranslation()
  return (
    <div>
      <Seo
        title={t('trending.meta.title')}
        description={t('trending.meta.description')}
        link="/trending"
        lang={i18n.language}
      />
      <div className="container-trending">
        <CardGroupHeader
          title="trending"
          data={trendingData}
          titleUrl="/trending"
        />
        <div className="trending-box">
          <CardList title="all" data={data} />
          {!_.isEmpty(eventsData) && (
            <CardSection
              title="all-events"
              category="events"
              data={eventsData}
            />
          )}
          {/* {!_.isEmpty(nftStoriesData) && (
            <CardSection
              title="all-nftstories"
              category="nft-stories"
              data={nftStoriesData}
            />
          )} */}
          {!_.isEmpty(productsData) && (
            <CardSection
              title="all-products"
              category="product-news"
              data={productsData}
            />
          )}
          {!_.isEmpty(companyData) && (
            <CardSection
              title="all-company"
              category="company-news"
              data={companyData}
            />
          )}
          {!_.isEmpty(universityData) && (
            <CardSection
              title="all-university"
              category="university"
              data={universityData}
            />
          )}
        </div>
        <ViewMobile title={t('trending.all')} data={mobileViewData} />
        <AdsSection />
      </div>
    </div>
  )
}

export default Trending
