import './view-mobile.scss'

import _ from 'lodash'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Card, Carousel, Title } from '../../../../components'

const STEP_SHOW = 8
const INIT_SHOW_NUMBER = 8

const ViewMobile = ({ data, title }) => {
  const { t } = useTranslation()
  const titles = ['events', 'products', 'company', 'university']
  const categories = ['events', 'product-news', 'company-news', 'university']
  let titleId = 0
  const [showNumber, setShowNumber] = useState(INIT_SHOW_NUMBER)
  const onShowMore = useCallback(() => {
    setShowNumber((prev) => prev + STEP_SHOW)
  }, [])
  return (
    <div className="view-mobile-container">
      <Title title={title} />
      {_.map(data.slice(0, showNumber), (item, idx) => {
        const titleTop = titles[titleId]
        const category = categories[titleId]
        switch (true) {
          case _.isArray(item):
            titleId += 1
            return (
              <React.Fragment key={`Section${titleId}`}>
                <Card key={item[0].node.id} item={item[0].node} />
                <Card key={item[1].node.id} item={item[1].node} />
                <Carousel
                  title={titleTop}
                  key={idx}
                  data={item.slice(2, 6)}
                  category={category}
                />
              </React.Fragment>
            )
          default:
            return <Card key={idx} item={item.node} />
        }
      })}
      {showNumber < data.length && (
        <div className="box-button-view-mobile">
          <Button title={t('ui.buttons.more')} onPress={onShowMore} />
        </div>
      )}
    </div>
  )
}

export default ViewMobile
