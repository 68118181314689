import { useUserRegionCH } from '@cdc-internal/mkt-nav-footer'
import _ from 'lodash'
import React, { useMemo } from 'react'

import { getTrendingPost } from '@/helpers/get-trending-posts'
import { useUKAccess } from '@/modules/useUKAccess'

import TrendingView from './trending-view'

const sortByDate = (posts) =>
  posts.sort(
    (a, b) =>
      new Date(b.node.dateGmt).getTime() - new Date(a.node.dateGmt).getTime()
  )

const getTrendingPosts = (allNews) => {
  const excludeCategoryList = ['market-updates']
  const trendingEl = getTrendingPost(
    allNews.filter((post) => {
      const postCats = post.node?.categories?.nodes
      if (!postCats) {
        return false
      }
      for (let i = postCats.length - 1; i >= 0; i -= 1) {
        if (excludeCategoryList.indexOf(postCats[i].description) !== -1) {
          return false
        }
      }
      return true
    })
  )
  return trendingEl
}

const TrendingController = ({ data }) => {
  const isUKIp = useUKAccess()
  const userRegion = useUserRegionCH()

  const universityFilteredData = useMemo(() => {
    let filteredData = data.allWpUniversityPost.edges
    if (isUKIp) {
      filteredData = _.filter(filteredData, (item) => {
        return _.every(
          item.node.categories.nodes,
          (category) => category.slug !== 'derivatives-university-en'
        )
      })
    }
    return filteredData
  }, [data.allWpUniversityPost.edges, isUKIp])

  const productNewsFilteredData = useMemo(() => {
    let filteredData = data.allWpProductSingleNews.edges
    if (userRegion === 'FR') {
      filteredData = _.filter(filteredData, (item) => {
        return _.every(
          item.node.categories.nodes,
          (category) => category.slug !== 'exchange-product-news-en'
        )
      })
    } else if (userRegion === 'AE') {
      filteredData = _.filter(filteredData, (item) => {
        return _.every(
          item.node.categories.nodes,
          (category) => category.slug !== 'pay-product-news-en'
        )
      })
    }
    return filteredData
  }, [data.allWpProductSingleNews.edges, userRegion])

  const allNews = useMemo(() => {
    return [
      ...data.allWpEvent.edges,
      ...universityFilteredData,
      ...productNewsFilteredData,
      ...data.allWpReportsAndanalysis.edges,
      ...data.allWpCompanySingleNews.edges,
      ...data.allWpMarketUpdate.edges,
      // ...data.allWpNftStory.edges,
    ].sort(
      (a, b) =>
        new Date(b.node.dateGmt).getTime() - new Date(a.node.dateGmt).getTime()
    )
  }, [data, universityFilteredData, productNewsFilteredData])

  const allMobileViewData = useMemo(() => {
    return [
      data.allWpEvent.edges.slice(0, 6),
      // data.allWpNftStory.edges.slice(0, 6),
      productNewsFilteredData.slice(0, 6),
      data.allWpCompanySingleNews.edges.slice(0, 6),
      universityFilteredData.slice(0, 6),
      ...allNews,
    ]
  }, [allNews, data, productNewsFilteredData, universityFilteredData])

  const trendingData = useMemo(() => getTrendingPosts(allNews), [allNews])

  const viewProps = {
    trendingData,
    mobileViewData: allMobileViewData,
    data: allNews,
    eventsData: sortByDate(data.allWpEvent.edges.slice(0, 4)),
    universityData: sortByDate(universityFilteredData.slice(0, 4)),
    companyData: sortByDate(data.allWpCompanySingleNews.edges.slice(0, 4)),
    productsData: sortByDate(productNewsFilteredData.slice(0, 4)),
    // nftStoriesData: sortByDate(data.allWpNftStory.edges.slice(0, 4)),
  }

  return <TrendingView {...viewProps} />
}

export default TrendingController
